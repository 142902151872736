.c-bodytext {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-corporate font-bold;

    color: var(--headline-color, #5049a4);

    &.align-center {
      @apply text-center;
    }

    &.align-right {
      @apply text-right;
    }
  }

  h1 {
    @apply text-4xl md:text-6xl mb-4 md:mb-8 leading-none;
  }

  h2 {
    @apply text-3xl md:text-5xl mb-3 leading-none;
  }

  h3 {
    @apply text-xl md:text-3xl mb-2 leading-tight;
  }

  h4 {
    @apply text-lg md:text-2xl leading-tight;
  }

  h5 {
    @apply text-lg md:text-xl leading-tight;
  }

  h6 {
    @apply text-lg leading-tight;
  }

  p {
    @apply mb-6;

    &.align-center {
      @apply text-center;
    }

    &.align-right {
      @apply text-right;
    }
  }

  ul, ol {
    @apply mb-6 ml-6;

    > li {
      @apply mt-2;
    }

    ul, ol {
      @apply mb-0 pl-6;
    }
  }

  ul {
    @apply list-disc;

    ul {
      @apply list-[circle];
    }
  }

  ol {
    @apply list-decimal;
  }

  a:not(.button) {
    @apply underline hover:no-underline;
  }

  .button {
    @apply relative inline-block border-x-2 border-current px-6 py-3 text-xl font-semibold hover:before:right-0 hover:after:left-0;
    @apply before:absolute before:left-0 before:right-3 before:top-0 before:h-[2px] before:bg-current before:transition-all;
    @apply after:absolute after:bottom-0 after:left-3 after:right-0 after:h-[2px] after:bg-current after:transition-all;

    color: var(--theme-color, #5049a4);
    background: var(--button-bg-color, #fff);
  }

  .hidden-phone {
    @apply max-sm:hidden;
  }
}
